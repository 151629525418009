import React from "react";

import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout>
    <h1>Hibás oldal, próbálja újra!</h1>
  </Layout>
);

export const Head = () => <title>Szelence Café | 404</title>;

export default NotFoundPage;
